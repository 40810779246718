import Img from "gatsby-image"
import { StaticQuery, graphql, Link } from 'gatsby'
import React from "react"
import styled from 'styled-components';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

export default () => {
  return (
    <StaticQuery
      query={graphql`
      query LandinFeaturesImageQuery {
        allImagesYaml {
          edges {
            node {
              id
              alt
              image {
                childImageSharp {
                  fluid (quality:80, maxWidth:1200) {
                    ...GatsbyImageSharpFluid_withWebp_tracedSVG
                  }
                }
              }
            }
          }
        }
      }
      `}

      render={data => {

        const settings = {
          dots: true,
          infinite: true,
          speed: 500,
          slidesToShow: 1,
          slidesToScroll: 1
        };
        const LandingBackground = styled.div`
    width: auto;
    margin-top:2rem;
    height: 60vh;
    background-color: #333333;
    @media (max-width: 600px) {
      height: 30vh;
      margin-top:2rem;
    }
    `
        const LandingTextWrapper = styled.div`
        margin-left:5rem;
        padding-top:2rem;
        margin-right: 5rem;
        color:#74ffc5;
        @media (max-width: 600px) {
        margin: 1rem
        }
    `
        const LandingDescription = styled.h4`
       font-weight:100;
       font-size:1rem;
       color:white;
    `
        const Title = styled.h3`
        color:#74ffc5;
        font-weight:bolder
 `
        return (
          <LandingBackground>
            <LandingTextWrapper>
              <Title>Laundry 4.0</Title>
              <LandingDescription>Prolacs has a new generation IoT solutions for laundries designed with industry 4.0 inspiration.</LandingDescription>
            </LandingTextWrapper>
            <div>
              <Slider {...settings}>
                {
                  data.allImagesYaml.edges.map(({ node }) => (
                    <div key={node.id}>
                      <Img
                        fluid={node.image.childImageSharp.fluid}
                        alt={node.alt}

                      />
                    </div>
                  ))
                }
              </Slider>
            </div>
          </LandingBackground>
        )
      }}
    />
  )
}