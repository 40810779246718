import Img from "gatsby-image"
import { StaticQuery, graphql, Link } from 'gatsby'
import React from "react"
import styled from 'styled-components';
import { Container, Row, Col } from "react-bootstrap"

export default () => {
  return (
    <StaticQuery
      query={graphql`
      query{
        oee: file(name: {eq: "oee"}) {
          childImageSharp {
            fluid(maxWidth: 800) {
              ...GatsbyImageSharpFluid_withWebp_tracedSVG
            }
          }
        }
        employee: file(name: {eq: "employee"}) {
          childImageSharp {
            fluid(maxWidth: 800) {
              ...GatsbyImageSharpFluid_withWebp_tracedSVG
            }
          }
        }
        consumption: file(name: {eq: "consumption"}) {
          childImageSharp {
            fluid(maxWidth: 800) {
              ...GatsbyImageSharpFluid_withWebp_tracedSVG
            }
          }
        }
      }
      
        
      `}

      render={data => {
        const CenteredRow = styled(Row)`
     align-items:center;
     margin-top:5rem;
    `
        const CenteredRowReverse = styled(Row)`
    align-items:center;
    margin-top:5rem;
    flex-flow: wrap-reverse;
   `
        const ColImage = styled(Col)`
   @media (max-width: 600px) {
    margin-bottom:1rem
  }
  `
        const Bold = styled.h2`
font-weight: bold;
 `
        return (
          <Container>
            <CenteredRow>
              <ColImage sm>
                <Img fluid={data.oee.childImageSharp.fluid} alt="prolacs consumption management and reducing costs" />
              </ColImage>
              <Col sm>
                <Bold>Consumption Management <br />
                  & Reducing Costs</Bold>
                <p>Prolacs LMS enables real-time monitoring and analysis of the data of energy, chemical and water consumption of industrial laundries.
It collects consumption data in line with the needs of the enterprises as machine, program and hour options.
Creates graphs, tables and reports in seconds by using the collected data. It helps to control the consumption costs and increases the profit rates of the enterprises.</p>
              </Col>
            </CenteredRow>
            <CenteredRowReverse>
              <ColImage sm>
                <Bold>Employee Productivity <br />
                  Reduce Labor Cost</Bold>
                <p>Prolacs increases your employee productivity by 10% since you start using laundry solutions in your laundry.Take control of work processes and reduce employee costs.</p>
              </ColImage>
              <Col sm>
                <Img fluid={data.employee.childImageSharp.fluid} alt=" prolacs Employee Productivity Reduce Labor Costs" />
              </Col>
            </CenteredRowReverse>
            <CenteredRow>
              <ColImage sm>
                <Img fluid={data.oee.childImageSharp.fluid} alt="prolacs consumption management and reducing costs" />
              </ColImage>
              <Col sm>
                <Bold>Overall Equipment Effectiveness <br />
                  Improve Efficiency</Bold>
                <p>Prolacs LMS, instead of unnecessary purchases of industrial laundries, allows the performance of the machinery and equipment they own. It can provide productivity increase in enterprises starting from minimum 10% up to 40%.

                <br />   Track equipment downtime, maintenance activities, and equipment failures to help improve overall equipment effectiveness.</p>
              </Col>
            </CenteredRow>
          </Container>
        )
      }}
    />
  )
}